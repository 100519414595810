import React from "react";
import { CustomButton } from "../components/common/customButton";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so next render shows fallback UI.
    if (
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed")
    ) {
      if (import.meta.env.MODE === "development") return;

      window.location.reload();
      return { hasError: false };
    }
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log error to an error reporting service
    logErrorToService(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return <FallbackComponent />;
    }
    return this.props.children;
  }
}

// Dummy implementation of logErrorToService and FallbackComponent
function logErrorToService(error: Error, errorInfo: React.ErrorInfo): void {
  console.error("Error:", error);
  console.error("Error Info:", errorInfo);
}

const FallbackComponent: React.FC = () => {
  return (
    <div className="w-full h-full flex-grow flex items-center justify-center flex-col gap-20 text-3xl">
      Произошла ошибка
      <a href="/">
        <CustomButton>Вернуться на главную страницу</CustomButton>
      </a>
    </div>
  );
};

export default ErrorBoundary;
